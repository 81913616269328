<template>
  <!-- menu -->
  <ul id="menu-principal" class="navbar-nav" v-if="item">
    <li
      class="nav-item dropdown"
      v-for="(menu, index) in menus"
      :key="menu.id"
      :class="{ 'has-megamenu': menu.menu_item_hijas.length >= 8 }"
    >
      <a
        v-if="menu.menu_item_hijas.length > 0"
        href="#"
        class="nav-link dropdown-toggle"
        :style="applyHoverStyle(index)"
        @mouseover="changeOver(index)"
        @mouseleave="changeLeave()"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        :id="'menu-' + menu.id"
      >
        {{ menu.nombre }}
      </a>
      <router-link
        v-else
        :to="menu.url"
        class="nav-link"
        :id="'menu' + menu.id"
      >
        {{ menu.nombre }}</router-link
      >

      <ul
        v-if="menu.menu_item_hijas.length > 0"
        class="dropdown-menu menu p-0 m-0"
        :aria-labelledby="'menu' + menu.id"
      >
        <div
          :class="[
            menu.menu_item_hijas.length > 10 ? 'row' : '',
            dynamicStyle,
            'm-0 p-0',
          ]"
          :style="{ '--color-primary': config.disenio.datos.color_primario }"
        >
          <li
            v-for="submenu in menu.menu_item_hijas"
            :key="submenu.id"
            :class="[
              menu.menu_item_hijas.length > 10 ? 'col-md-6' : '',
              submenu.menu_item_hijas.length > 0 ? 'dropdown-submenu' : '',
            ]"
          >
            <a
              v-if="isExterno(submenu.url)"
              :href="submenu.url"
              class="dropdown-item"
              target="_blank"
              >{{ submenu.nombre }}</a
            >
            <router-link v-else class="dropdown-item" :to="submenu.url">{{
              submenu.nombre
            }}</router-link>
            <sub-menu
              v-if="submenu.menu_item_hijas.length > 0"
              :submenus="submenu.menu_item_hijas"
              :key="submenu.id"
            ></sub-menu>
          </li>
        </div>
      </ul>
    </li>
    <a
      v-if="visiblemenu"
      @click="open_menu()"
      href="javascript:;"
      class="btn-menu-lateral"
      id="btn-menu-open"
    >
      <i class="icon">
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.3917 12.8333H4.60833C3.99622 12.8333 3.5 13.3295 3.5 13.9416V14.0583C3.5 14.6704 3.99622 15.1666 4.60833 15.1666H23.3917C24.0038 15.1666 24.5 14.6704 24.5 14.0583V13.9416C24.5 13.3295 24.0038 12.8333 23.3917 12.8333Z"
            fill="white"
          />
          <path
            d="M23.3917 18.6667H4.60833C3.99622 18.6667 3.5 19.1629 3.5 19.775V19.8917C3.5 20.5038 3.99622 21 4.60833 21H23.3917C24.0038 21 24.5 20.5038 24.5 19.8917V19.775C24.5 19.1629 24.0038 18.6667 23.3917 18.6667Z"
            fill="white"
          />
          <path
            d="M23.3917 7H4.60833C3.99622 7 3.5 7.49622 3.5 8.10833V8.225C3.5 8.83712 3.99622 9.33333 4.60833 9.33333H23.3917C24.0038 9.33333 24.5 8.83712 24.5 8.225V8.10833C24.5 7.49622 24.0038 7 23.3917 7Z"
            fill="white"
          />
        </svg>
      </i>
    </a>
  </ul>
</template>
<script>
import axiosIns from "@/libs/axios";
import SubMenu from "./menu/SubMenu.vue";

export default {
  name: "MenuPrincipal",
  components: {
    SubMenu,
  },
  props: ["scroll"],
  data() {
    return {
      item: [],
      menus: [],
      isHovered: null,
    };
  },
  mounted() {
    this.listar();
  },
  computed: {
    visiblemenu() {
      return this.$store.state.visible_menu;
    },

    config() {
      return this.$store.state.config;
    },
    dynamicStyle() {
      return this.scroll > 50 ? "fondo-menu" : "";
    },
  },
  methods: {
    listar() {
      var url = "/web/menu-principal";
      axiosIns
        .get(url)
        .then((res) => {
          this.item = res.data;
          this.menus = this.item.menuitems;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    isExterno(url) {
      if (url.indexOf("http") == 0) return true;
      else return false;
    },
    open_menu() {
      this.$store.commit("setOpenMenu", 1);
      document.body.classList.toggle("nav-is-toggled");
    },
    changeOver(index) {
      this.isHovered = index;
    },
    changeLeave() {
      this.isHovered = null;
    },
    applyHoverStyle(index) {
      // Si está en hover, devuelve el color con !important
      if (this.isHovered === index && this.scroll > 50) {
        return `color: ${this.config.disenio.datos.color_primario} !important`;
      }
      return "color: #000"; // Color por defecto
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.fondo-menu {
  border-style: solid none solid none;
  border-width: 0.5rem;
  border-color: var(--color-primary);
}
</style>
