<template>
  <div>
    <header
      id="header-web"
      class="fixed-top"
      :class="{ 'scroll-header': scrollPosition > 50 }"
    >
      <div class="header-top" :class="{ 'header-top-inicio': paginaInicio }">
        <div class="container">
          <router-link class="me-5" to="/">
            <img
              class="mt-2 mb-2 logo"
              :src="config.disenio.datos.logo_white"
            />
          </router-link>
          <div class="menu-superior float-end">
            <!-- menu superior -->
            <menu-superior></menu-superior>
          </div>
        </div>
      </div>
      <nav
        id="header-topbar"
        class="navbar navbar-expand-md navbar-light"
        :class="{ sombras: scrollPosition <= 50 && paginaInicio }"
      >
        <div class="container">
          <div class="main-menu" id="navbarCollapse">
            <router-link class="navbar-brand" to="/">
              <img
                class="mt-2 mb-2 logo"
                :src="config.disenio.datos.logo_dark"
              />
            </router-link>
            <!-- menu principal -->
            <menu-principal :scroll="scrollPosition"></menu-principal>
          </div>
        </div>
      </nav>
    </header>

    <menu-lateral></menu-lateral>
  </div>
</template>

<script>
import MenuSuperior from "../components/MenuSuperior.vue";
import MenuPrincipal from "../components/MenuPrincipal.vue";
import MenuLateral from "../components/MenuLateral.vue";

export default {
  name: "HeaderWeb",
  data() {
    return {
      scrollPosition: null,
      paginaInicio: true,
      path: null,
    };
  },
  components: {
    MenuSuperior,
    MenuPrincipal,
    MenuLateral,
  },
  computed: {
    config() {
      return this.$store.state.config;
    },
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    this.path = window.location.pathname;

    if (this.path == "/") this.paginaInicio = true;
    this.$router.afterEach((to) => {
      this.path = !to.path ? this.path : to.path;

      if (this.path == "/") this.paginaInicio = true;
      else this.paginaInicio = false;
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
