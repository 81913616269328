<template>
  <div id="app">
    <header-web></header-web>
    <main
      :style="{
        backgroundColor: configPrincipal.disenio.datos.color_fondo,
        '--bg-secondary': configPrincipal.disenio.datos.color_secundario,
      }"
    >
      <router-view></router-view>
      <footer-web></footer-web>
      <popup-modal></popup-modal>
    </main>
  </div>
</template>

<script>
import HeaderWeb from "./components/HeaderWeb.vue";
import FooterWeb from "./components/FooterWeb.vue";
import PopupModal from "./views/popup/PopupModal.vue";
import axiosIns from "@/libs/axios";

export default {
  name: "App",
  components: {
    HeaderWeb,
    FooterWeb,
    PopupModal,
  },
  data() {
    return {
      config: {
        disenio: [],
        sitio: [],
        fiscaliatv: [],
        videoftv: [],
        redes_sociales: [],
      },
    };
  },
  created() {
    this.getConfig();
  },
  mounted() {},
  computed: {
    configPrincipal() {
      return this.$store.state.config;
    },
  },
  methods: {
    getConfig() {
      var url = "/web/config";
      axiosIns
        .get(url)
        .then((res) => {
          if (res.data.data.disenio.valor)
            this.config.disenio = JSON.parse(res.data.data.disenio.valor);

          if (res.data.data.fiscaliatv.valor)
            this.config.fiscaliatv = JSON.parse(res.data.data.fiscaliatv.valor);

          if (res.data.data.videoftv)
            this.config.videoftv = res.data.data.videoftv;

          if (res.data.data.redes_sociales.valor)
            this.config.redes_sociales = JSON.parse(
              res.data.data.redes_sociales.valor
            );

          if (res.data.data.sitio.valor) {
            this.config.sitio = JSON.parse(res.data.data.sitio.valor);

            let external_script = document.createElement("script");
            external_script.setAttribute("src", "/assets/js/chat.js");
            if (this.config.sitio.datos.chat_facebook == "1")
              document.body.appendChild(external_script);
          }
          this.$store.commit("setConfig", this.config);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
#app {
  padding: 0;
  margin: 0;
}
</style>
