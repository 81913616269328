var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.item)?_c('ul',{staticClass:"navbar-nav",attrs:{"id":"menu-principal"}},[_vm._l((_vm.menus),function(menu,index){return _c('li',{key:menu.id,staticClass:"nav-item dropdown",class:{ 'has-megamenu': menu.menu_item_hijas.length >= 8 }},[(menu.menu_item_hijas.length > 0)?_c('a',{staticClass:"nav-link dropdown-toggle",style:(_vm.applyHoverStyle(index)),attrs:{"href":"#","role":"button","data-bs-toggle":"dropdown","aria-expanded":"false","id":'menu-' + menu.id},on:{"mouseover":function($event){return _vm.changeOver(index)},"mouseleave":function($event){return _vm.changeLeave()}}},[_vm._v(" "+_vm._s(menu.nombre)+" ")]):_c('router-link',{staticClass:"nav-link",attrs:{"to":menu.url,"id":'menu' + menu.id}},[_vm._v(" "+_vm._s(menu.nombre))]),(menu.menu_item_hijas.length > 0)?_c('ul',{staticClass:"dropdown-menu menu p-0 m-0",attrs:{"aria-labelledby":'menu' + menu.id}},[_c('div',{class:[
          menu.menu_item_hijas.length > 10 ? 'row' : '',
          _vm.dynamicStyle,
          'm-0 p-0',
        ],style:({ '--color-primary': _vm.config.disenio.datos.color_primario })},_vm._l((menu.menu_item_hijas),function(submenu){return _c('li',{key:submenu.id,class:[
            menu.menu_item_hijas.length > 10 ? 'col-md-6' : '',
            submenu.menu_item_hijas.length > 0 ? 'dropdown-submenu' : '',
          ]},[(_vm.isExterno(submenu.url))?_c('a',{staticClass:"dropdown-item",attrs:{"href":submenu.url,"target":"_blank"}},[_vm._v(_vm._s(submenu.nombre))]):_c('router-link',{staticClass:"dropdown-item",attrs:{"to":submenu.url}},[_vm._v(_vm._s(submenu.nombre))]),(submenu.menu_item_hijas.length > 0)?_c('sub-menu',{key:submenu.id,attrs:{"submenus":submenu.menu_item_hijas}}):_vm._e()],1)}),0)]):_vm._e()],1)}),(_vm.visiblemenu)?_c('a',{staticClass:"btn-menu-lateral",attrs:{"href":"javascript:;","id":"btn-menu-open"},on:{"click":function($event){return _vm.open_menu()}}},[_c('i',{staticClass:"icon"},[_c('svg',{attrs:{"width":"28","height":"28","viewBox":"0 0 28 28","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M23.3917 12.8333H4.60833C3.99622 12.8333 3.5 13.3295 3.5 13.9416V14.0583C3.5 14.6704 3.99622 15.1666 4.60833 15.1666H23.3917C24.0038 15.1666 24.5 14.6704 24.5 14.0583V13.9416C24.5 13.3295 24.0038 12.8333 23.3917 12.8333Z","fill":"white"}}),_c('path',{attrs:{"d":"M23.3917 18.6667H4.60833C3.99622 18.6667 3.5 19.1629 3.5 19.775V19.8917C3.5 20.5038 3.99622 21 4.60833 21H23.3917C24.0038 21 24.5 20.5038 24.5 19.8917V19.775C24.5 19.1629 24.0038 18.6667 23.3917 18.6667Z","fill":"white"}}),_c('path',{attrs:{"d":"M23.3917 7H4.60833C3.99622 7 3.5 7.49622 3.5 8.10833V8.225C3.5 8.83712 3.99622 9.33333 4.60833 9.33333H23.3917C24.0038 9.33333 24.5 8.83712 24.5 8.225V8.10833C24.5 7.49622 24.0038 7 23.3917 7Z","fill":"white"}})])])]):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }